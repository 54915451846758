var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Provider } from 'mobx-react';
import ConfigProvider from "antd/lib/config-provider";
import "antd/lib/config-provider/style/css.js";
import ko_KR from 'antd/lib/locale-provider/ko_KR';
import { BrowserRouter } from 'react-router-dom';
import { rootStore } from './stores';
import { RootRouter } from './utils';
import Router from './Router';
import React from 'react';
var styles = require('src/common/less/ant.override.less');
var App = function () { return (React.createElement("div", { className: styles.root },
    React.createElement(Provider, __assign({}, rootStore),
        React.createElement(ConfigProvider, { locale: ko_KR },
            React.createElement(BrowserRouter, { ref: RootRouter.routerRef },
                React.createElement(Router, null)))))); };
export default App;
