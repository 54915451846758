var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import Pages from 'src/pages/index';
import { RootLayout } from 'src/components';
import { default as Config } from 'src/common/Config';
var MENU_KEY_LIST = Config.MENU_KEY_LIST;
import PrivateRoute from './PrivateRoute';
var Router = /** @class */ (function (_super) {
    __extends(Router, _super);
    function Router() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Router.prototype.componentDidUpdate = function (prevProps) {
        var location = this.props.location;
        if (location !== prevProps.location) {
            window.scrollTo(0, 0);
        }
    };
    Router.prototype.render = function () {
        return (React.createElement("div", null,
            React.createElement(RootLayout, null,
                React.createElement(Switch, null,
                    React.createElement(Route, { exact: true, path: "/500", component: Pages.Account.Down }),
                    React.createElement(Redirect, { exact: true, from: "/", to: "/notice" }),
                    React.createElement(Route, { exact: true, path: "/logout", component: Pages.Account.Logout }),
                    React.createElement(Route, { exact: true, path: "/account/login", component: Pages.Account.Login }),
                    React.createElement(PrivateRoute, { path: "/notice/write", component: Pages.Notice.Write, menu_id: MENU_KEY_LIST.Notice.Write }),
                    React.createElement(PrivateRoute, { path: "/notice/:post_id/edit", component: Pages.Notice.Edit, menu_id: MENU_KEY_LIST.Notice.Edit }),
                    React.createElement(PrivateRoute, { path: "/notice/:post_id", component: Pages.Notice.Detail, menu_id: MENU_KEY_LIST.Notice.Detail }),
                    React.createElement(PrivateRoute, { path: "/notice", component: Pages.Notice.List, menu_id: MENU_KEY_LIST.Notice.List }),
                    React.createElement(PrivateRoute, { path: "/order/write", component: Pages.Order.Write, menu_id: MENU_KEY_LIST.OrderCreation }),
                    React.createElement(PrivateRoute, { path: "/order/:order_id/edit", component: Pages.Order.Edit, menu_id: MENU_KEY_LIST.Order.Edit }),
                    React.createElement(PrivateRoute, { path: "/order/:order_id", component: Pages.Order.Detail, menu_id: MENU_KEY_LIST.Order.Detail }),
                    React.createElement(PrivateRoute, { path: "/order", component: Pages.Order.List, menu_id: MENU_KEY_LIST.Order.List }),
                    React.createElement(PrivateRoute, { path: "/product/:product_id/edit", component: Pages.Product.Edit, menu_id: MENU_KEY_LIST.Product.Edit }),
                    React.createElement(PrivateRoute, { path: "/product/write", component: Pages.Product.Write, menu_id: MENU_KEY_LIST.Product.Write }),
                    React.createElement(PrivateRoute, { path: "/product", component: Pages.Product.List, menu_id: MENU_KEY_LIST.Product.List }),
                    React.createElement(PrivateRoute, { path: "/product_category/:product_category_id/edit", component: Pages.ProductCategory.Edit, menu_id: MENU_KEY_LIST.ProductCategory.Edit }),
                    React.createElement(PrivateRoute, { path: "/product_category/write", component: Pages.ProductCategory.Write, menu_id: MENU_KEY_LIST.ProductCategory.Write }),
                    React.createElement(PrivateRoute, { path: "/product_category", component: Pages.ProductCategory.List, menu_id: MENU_KEY_LIST.ProductCategory.List }),
                    React.createElement(PrivateRoute, { path: "/customer/:customer_id/edit", component: Pages.Customer.Edit, menu_id: MENU_KEY_LIST.Customer.Edit }),
                    React.createElement(PrivateRoute, { path: "/customer/write", component: Pages.Customer.Write, menu_id: MENU_KEY_LIST.Customer.Write }),
                    React.createElement(PrivateRoute, { path: "/customer", component: Pages.Customer.List, menu_id: MENU_KEY_LIST.Customer.List }),
                    React.createElement(PrivateRoute, { path: "/customer_type/:customer_type_id/edit", component: Pages.CustomerType.Edit, menu_id: MENU_KEY_LIST.CustomerType.Edit }),
                    React.createElement(PrivateRoute, { path: "/customer_type/write", component: Pages.CustomerType.Write, menu_id: MENU_KEY_LIST.CustomerType.Write }),
                    React.createElement(PrivateRoute, { path: "/customer_type", component: Pages.CustomerType.List, menu_id: MENU_KEY_LIST.CustomerType.List }),
                    React.createElement(PrivateRoute, { path: "/settlement/summary", component: Pages.Settlement.Summary, menu_id: MENU_KEY_LIST.Settlement.Summary }),
                    React.createElement(PrivateRoute, { path: "/settlement/:customer_id/monthly/:base_date", component: Pages.Settlement.Detail, menu_id: MENU_KEY_LIST.Settlement.Detail }),
                    React.createElement(PrivateRoute, { path: "/settlement/monthly/:base_date", component: Pages.Settlement.Detail, menu_id: MENU_KEY_LIST.Settlement.Detail }),
                    React.createElement(PrivateRoute, { path: "/settlement/:customer_id", component: Pages.Settlement.List, menu_id: MENU_KEY_LIST.Settlement.List }),
                    React.createElement(PrivateRoute, { path: "/settlement/", component: Pages.Settlement.List, menu_id: MENU_KEY_LIST.Settlement.List }),
                    React.createElement(PrivateRoute, { path: "/mypage", component: Pages.MyPage, menu_id: MENU_KEY_LIST.MyPage }),
                    React.createElement(Redirect, { to: "/404" })))));
    };
    return Router;
}(React.Component));
export default withRouter(Router);
