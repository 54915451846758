import * as React from 'react';
var routerRef = React.createRef();
function push(path) {
    var _a;
    (_a = routerRef.current) === null || _a === void 0 ? void 0 : _a.history.push(path);
}
function replace(location) {
    var _a;
    (_a = routerRef.current) === null || _a === void 0 ? void 0 : _a.history.replace(location);
}
export default {
    routerRef: routerRef,
    push: push,
    replace: replace,
};
