var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
var PrivatePage = /** @class */ (function (_super) {
    __extends(PrivatePage, _super);
    function PrivatePage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /*
     * 현재 라우팅된 페이지 내에서만 appStore.authStatusLoading가 변경때
     * 렌더링하기 위해 PrivatePage를 분리하고 observer를 연결함
     */
    PrivatePage.prototype.render = function () {
        var _a = this.props, appStore = _a.appStore, routerProps = _a.routerProps, customProps = _a.customProps, component = _a.component, menu_id = _a.menu_id;
        /**
         * 최초로 인증 상태를 가져오는 동안에는 로그인 리다이렉트 보류
         */
        if (appStore.isInitialized) {
            return React.createElement("div", null, "\uB85C\uB529 \uC911...");
        }
        if (!appStore.user) {
            return React.createElement(Redirect, { to: {
                    pathname: '/account/login',
                    state: { from: routerProps.location }
                } });
        }
        appStore.fetchServerManagementData();
        if (!appStore.user.is_superuser && appStore.serverStatus) {
            return React.createElement(Redirect, { to: {
                    pathname: '/500',
                } });
        }
        else {
            return React.createElement(component, __assign(__assign({}, routerProps), customProps));
        }
    };
    ;
    PrivatePage = __decorate([
        inject('appStore'),
        observer
    ], PrivatePage);
    return PrivatePage;
}(React.Component));
function PrivateRoute(_a) {
    var path = _a.path, component = _a.component, _b = _a.customProps, customProps = _b === void 0 ? {} : _b, menu_id = _a.menu_id;
    return (React.createElement(Route, { exact: true, path: path, render: function (routerProps) { return React.createElement(PrivatePage, { routerProps: routerProps, component: component, customProps: customProps, menu_id: menu_id }); } }));
}
export default PrivateRoute;
