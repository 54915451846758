import _ from 'lodash';
import { default as Message } from "antd/lib/message";
import "antd/lib/message/style/css.js";
var ErrorHandler = /** @class */ (function () {
    function ErrorHandler() {
    }
    ErrorHandler.showResponse = function (_a) {
        var _b, _c, _d, _e, _f, _g, _h;
        var e = _a.e, _j = _a.errorPath, errorPath = _j === void 0 ? '' : _j, _k = _a.memo, memo = _k === void 0 ? '' : _k;
        var message = '';
        if (memo)
            memo = "[" + memo + "]";
        if (e.response) {
            var res = e.response;
            console.log(res);
            if (errorPath) {
                message = _.get(res, errorPath);
            }
            else if ((_b = res === null || res === void 0 ? void 0 : res.body) === null || _b === void 0 ? void 0 : _b.message) {
                message = res.body.message;
            }
            else if ((_c = res === null || res === void 0 ? void 0 : res.body) === null || _c === void 0 ? void 0 : _c.errmsg) {
                message = res.body.errmsg;
            }
            else if (res === null || res === void 0 ? void 0 : res.data) {
                // @ts-ignore
                message = Object.entries(res.data)[0][1];
            }
            if (message) {
                if (memo)
                    message = message + " " + memo;
                Message.error(message, 5);
            }
            else if (res.status === 504) {
                Message.error("\uC778\uD130\uB137 \uC5F0\uACB0 \uC0C1\uD0DC\uB97C \uD655\uC778\uD574\uC8FC\uC138\uC694. " + memo);
            }
            else if (res.status === 403) {
                Message.warning('권한이 없습니다.', 5);
            }
            else {
                Message.error("\uC624\uB958\uAC00 \uBC1C\uC0DD\uD588\uC2B5\uB2C8\uB2E4. " + memo, 3);
            }
        }
        else if (e.networkError) {
            if ((_f = (_e = (_d = e.networkError) === null || _d === void 0 ? void 0 : _d.result) === null || _e === void 0 ? void 0 : _e.errors) === null || _f === void 0 ? void 0 : _f.length)
                message = e.networkError.result.errors[0].message;
            else
                message = (_h = (_g = e.networkError) === null || _g === void 0 ? void 0 : _g.result) === null || _h === void 0 ? void 0 : _h.detail;
            if (memo)
                message = message + " " + memo;
            Message.error(message, 4);
        }
        else if (e.graphQLErrors) {
            message = e.graphQLErrors[0].message;
            if (memo)
                message = message + " " + memo;
            Message.error(message, 5);
        }
        else {
            console.log('ErrorHandler 처리 예외 발생:', JSON.stringify(e));
            Message.error("\uC624\uB958\uAC00 \uBC1C\uC0DD\uD588\uC2B5\uB2C8\uB2E4. " + memo, 5);
        }
    };
    return ErrorHandler;
}());
export default ErrorHandler;
