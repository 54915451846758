var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { default as Message } from "antd/lib/message";
import "antd/lib/message/style/css.js";
import message from "antd/lib/message";
import "antd/lib/message/style/css.js";
import { observable, action } from 'mobx';
import { RootRouter, ErrorHandler, Request } from 'src/utils';
var AppStore = /** @class */ (function () {
    function AppStore(rootStore) {
        var _this = this;
        /**
         * 인증상태, 메뉴 리스트를 가져오는 동안에는 로그인 리다이렉트를 보류하기 위해
         */
        this.isInitialized = false;
        this.passwordEditModal = {
            visible: false,
            current_password: '',
            new_password: '',
            new_password_check: '',
        };
        this.serverManagementData = {
            'serverStop': null,
            'bankName': null,
            'accountNumber': null,
            'accountName': null,
            'noticeNewStatus': null
        };
        this.initialize = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isInitialized = true;
                        return [4 /*yield*/, this.getAuthStatus()];
                    case 1:
                        _a.sent();
                        this.isInitialized = false;
                        return [2 /*return*/];
                }
            });
        }); };
        this.login = function (username, password, from) { return __awaiter(_this, void 0, void 0, function () {
            var res, e_1, errMessage;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, Request.post('/api/account/login/').send({
                                'username': username,
                                'password': password
                            })];
                    case 1:
                        res = _b.sent();
                        this.user = res.body.user;
                        RootRouter.replace(from);
                        message.success('로그인되었습니다.');
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _b.sent();
                        errMessage = (_a = e_1.response) === null || _a === void 0 ? void 0 : _a.body.non_field_errors[0];
                        if (errMessage) {
                            Message.error(errMessage);
                        }
                        else {
                            Message.error('아이디와 비밀번호가 일치하지 않습니다.');
                        }
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.logout = function () { return __awaiter(_this, void 0, void 0, function () {
            var e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, Request.post('/api/account/logout/').send({})];
                    case 1:
                        _a.sent();
                        this.user = null;
                        RootRouter.push('/account/login');
                        message.success('로그아웃 되었습니다.');
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        ErrorHandler.showResponse(e_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.getAuthStatus = function () { return __awaiter(_this, void 0, void 0, function () {
            var res, e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, Request.get("/api/account/status/").send()];
                    case 1:
                        res = _a.sent();
                        this.user = res.body.user;
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _a.sent();
                        this.user = null;
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.fetchServerManagementData = function () { return __awaiter(_this, void 0, void 0, function () {
            var res, serverManagementDataList, err_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, Request.get("/api/utility/").send()];
                    case 1:
                        res = _a.sent();
                        serverManagementDataList = res.body;
                        serverManagementDataList.map(function (entry) {
                            _this.serverManagementData[entry.key] = entry.value;
                        });
                        this.serverStatus = this.serverManagementData.serverStop;
                        this.noticeNewStatus = this.serverManagementData.noticeNewStatus;
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        ErrorHandler.showResponse(err_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.rootStore = rootStore;
        this.initialize().then();
    }
    __decorate([
        observable
    ], AppStore.prototype, "isInitialized", void 0);
    __decorate([
        observable
    ], AppStore.prototype, "user", void 0);
    __decorate([
        observable
    ], AppStore.prototype, "passwordEditModal", void 0);
    __decorate([
        observable
    ], AppStore.prototype, "serverManagementData", void 0);
    __decorate([
        observable
    ], AppStore.prototype, "serverStatus", void 0);
    __decorate([
        observable
    ], AppStore.prototype, "noticeNewStatus", void 0);
    __decorate([
        action
    ], AppStore.prototype, "login", void 0);
    __decorate([
        action
    ], AppStore.prototype, "logout", void 0);
    __decorate([
        action
    ], AppStore.prototype, "getAuthStatus", void 0);
    __decorate([
        action
    ], AppStore.prototype, "fetchServerManagementData", void 0);
    return AppStore;
}());
export default AppStore;
