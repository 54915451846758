function reorder(list, startIndex, endIndex) {
    var result = Array.from(list);
    var removed = result.splice(startIndex, 1)[0];
    result.splice(endIndex, 0, removed);
    return result;
}
function move(source, destination, droppableSource, droppableDestination) {
    var sourceClone = Array.from(source);
    var destClone = Array.from(destination);
    var removed = sourceClone.splice(droppableSource.index, 1)[0];
    destClone.splice(droppableDestination.index, 0, removed);
    var result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
}
export default {
    reorder: reorder,
    move: move,
};
