var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import Layout from "antd/lib/layout";
import "antd/lib/layout/style/css.js";
import Menu from "antd/lib/menu";
import "antd/lib/menu/style/css.js";
var Header = Layout.Header, Content = Layout.Content, Sider = Layout.Sider;
var styles = require('./index.less');
import { default as Config } from 'src/common/Config';
var MENU_LIST = Config.MENU_LIST;
import _ from 'lodash';
var RootLayout = /** @class */ (function (_super) {
    __extends(RootLayout, _super);
    function RootLayout() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RootLayout.prototype.render = function () {
        var _a, _b, _c;
        var _d = this.props, children = _d.children, location = _d.location, appStore = _d.appStore;
        if (location.pathname === '/account/login' || location.pathname === '/500') {
            return (React.createElement(Layout, null,
                React.createElement(Content, { className: styles.emptyContent }, children)));
        }
        return (React.createElement(Layout, null,
            React.createElement(Sider, { className: styles.sider, width: 240 },
                React.createElement("div", { className: styles.inner },
                    React.createElement("p", null, (_a = appStore.user) === null || _a === void 0 ? void 0 : _a.company_name),
                    React.createElement(Menu, { mode: "vertical", defaultSelectedKeys: [location.pathname] },
                        ((_b = appStore.user) === null || _b === void 0 ? void 0 : _b.is_superuser) &&
                            [
                                this.MenuItemComponent(1, '/notice', appStore.serverManagementData.noticeNewStatus),
                                this.MenuItemComponent(3, '/order'),
                                this.MenuItemComponent(5, '/product'),
                                this.MenuItemComponent(6, '/product_category'),
                                this.MenuItemComponent(7, '/customer'),
                                this.MenuItemComponent(8, '/customer_type'),
                                this.MenuItemComponent(12, '/settlement'),
                                this.MenuItemComponent(10, '/mypage'),
                            ],
                        !((_c = appStore.user) === null || _c === void 0 ? void 0 : _c.is_superuser) &&
                            [
                                this.MenuItemComponent(1, '/notice', appStore.serverManagementData.noticeNewStatus),
                                this.MenuItemComponent(2, '/order'),
                                this.MenuItemComponent(4, '/order_create'),
                                this.MenuItemComponent(13, '/settlement'),
                                this.MenuItemComponent(9, '/mypage'),
                            ],
                        this.MenuItemComponent(11, '/logout')))),
            React.createElement(Layout, null,
                React.createElement(Header, null),
                React.createElement(Content, { className: styles.content }, children))));
    };
    RootLayout.prototype.MenuItemComponent = function (id, key, ovalIcon) {
        if (ovalIcon === void 0) { ovalIcon = false; }
        var menu = _.find(MENU_LIST, { id: id });
        return (React.createElement(Menu.Item, { key: key },
            React.createElement(Link, { to: menu.path },
                React.createElement("span", null,
                    menu.name,
                    ovalIcon && React.createElement("span", { className: styles.oval })))));
    };
    RootLayout = __decorate([
        inject('appStore'),
        observer
    ], RootLayout);
    return RootLayout;
}(React.Component));
export default withRouter(RootLayout);
