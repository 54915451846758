export default {
    SENTRY_CONFIG: {
        dsn: 'https://@sentry.io/',
        enableInExpoDevelopment: true,
        debug: true,
    },
    API_URL: 'http://192.168.0.25:8000',
    MENU_LIST: [
        { id: 1, name: '공지사항', path: '/notice' },
        { id: 2, name: '나의 주문내역', path: '/order/' },
        { id: 3, name: '주문 관리', path: '/order/' },
        { id: 4, name: '주문하기', path: '/order/write' },
        { id: 5, name: '상품 관리', path: '/product' },
        { id: 6, name: '상품 카테고리 관리', path: '/product_category' },
        { id: 7, name: '거래처 관리', path: '/customer' },
        { id: 8, name: '거래처 형태 관리', path: '/customer_type' },
        { id: 9, name: '내 정보 관리', path: '/mypage' },
        { id: 10, name: '관리자 정보 관리', path: '/mypage' },
        { id: 11, name: '로그아웃', path: '/logout' },
        { id: 12, name: '거래처 정산 관리', path: '/settlement/summary' },
        { id: 13, name: '정산 관리', path: '/settlement' },
    ],
    MENU_KEY_LIST: {
        Notice: {
            List: 1,
            Detail: 2,
            Edit: 3,
            Write: 4,
            Management: 5,
        },
        Order: {
            List: 6,
            Detail: 7,
            Edit: 8,
        },
        OrderCreation: 9,
        Product: {
            List: 10,
            Write: 11,
            Edit: 12,
        },
        ProductCategory: {
            List: 13,
            Write: 14,
            Edit: 15,
        },
        Customer: {
            List: 16,
            Write: 17,
            Edit: 18,
        },
        CustomerType: {
            List: 19,
            Write: 20,
            Edit: 21,
        },
        Settlement: {
            Summary: 25,
            List: 26,
            Detail: 27,
        },
        MyPage: 22,
        Login: 23,
        Logout: 24,
    }
};
