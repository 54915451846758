import '@babel/polyfill';
import 'mobx-react/batchingForReactDom';
import React from 'react';
import ReactDOM from 'react-dom';
import Spin from "antd/lib/spin";
import "antd/lib/spin/style/css.js";
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/ko';
moment.locale('ko');
import App from './App';
if (process.env.NODE_ENV !== 'production') {
    console.log('Looks like we are in development mode!');
}
// 기본 spinner 변경
var customSpinner = React.createElement(LoadingOutlined, { spin: true, style: {
        fontSize: 22,
    } });
Spin.setDefaultIndicator(customSpinner);
ReactDOM.render(React.createElement(App, null), document.querySelector('#app'));
