import _ from 'lodash';
var FormConverter = /** @class */ (function () {
    function FormConverter() {
    }
    /**
     * @param {Object} data
     * @return {Object}
     *
     * Example:
     * data: {
     *   head: {
     *     mouth: {
     *       teeth1: true,
     *       teeth2: false,
     *     }
     *   }
     * }
     * return: {'head.mouth.teeth1': true, 'head.mouth.teeth2': false}
     *
     */
    FormConverter.json2fields = function (data) {
        if (!_.isObject(data) && !_.isArray(data))
            return data;
        var result = {};
        for (var key in data) {
            if (!data.hasOwnProperty(key))
                continue;
            if (!_.isObject(_.get(data, key))) {
                result[key] = data[key];
            }
            else {
                var subData = FormConverter.json2fields(_.get(data, key));
                for (var subKey in subData) {
                    if (!subData.hasOwnProperty(subKey))
                        continue;
                    result[key + "." + subKey] = _.get(subData, subKey);
                }
            }
        }
        return result;
    };
    FormConverter.fields2json = function (fields) {
        var data = {};
        for (var field in fields) {
            if (!fields.hasOwnProperty(field))
                continue;
            _.set(data, field, fields[field]);
        }
        return data;
    };
    return FormConverter;
}());
export default FormConverter;
