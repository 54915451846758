import React from 'react';
import Loadable from 'react-loadable';
var Loading = function () { return React.createElement("div", null, "\uB85C\uB529 \uC911..."); };
var load = function (loader) {
    return Loadable({
        loader: loader,
        loading: Loading,
    });
};
export default {
    Notice: {
        Write: load(function () { return import('src/pages/Notice/Write/index'); }),
        Detail: load(function () { return import('src/pages/Notice/Detail/index'); }),
        List: load(function () { return import('src/pages/Notice/List/index'); }),
        Edit: load(function () { return import('src/pages/Notice/Edit/index'); }),
    },
    Order: {
        Write: load(function () { return import('src/pages/Order/Write/index'); }),
        Detail: load(function () { return import('src/pages/Order/Detail/index'); }),
        List: load(function () { return import('src/pages/Order/List/index'); }),
        Edit: load(function () { return import('src/pages/Order/Edit/index'); }),
    },
    Product: {
        Write: load(function () { return import('src/pages/Product/Write/index'); }),
        List: load(function () { return import('src/pages/Product/List/index'); }),
        Edit: load(function () { return import('src/pages/Product/Edit/index'); }),
    },
    ProductCategory: {
        Write: load(function () { return import('src/pages/ProductCategory/Write/index'); }),
        List: load(function () { return import('src/pages/ProductCategory/List/index'); }),
        Edit: load(function () { return import('src/pages/ProductCategory/Edit/index'); }),
    },
    Customer: {
        Write: load(function () { return import('src/pages/Customer/Write/index'); }),
        List: load(function () { return import('src/pages/Customer/List/index'); }),
        Edit: load(function () { return import('src/pages/Customer/Edit/index'); }),
    },
    CustomerType: {
        Write: load(function () { return import('src/pages/CustomerType/Write/index'); }),
        List: load(function () { return import('src/pages/CustomerType/List/index'); }),
        Edit: load(function () { return import('src/pages/CustomerType/Edit/index'); }),
    },
    Settlement: {
        Summary: load(function () { return import('src/pages/Settlement/Summary'); }),
        List: load(function () { return import('src/pages/Settlement/List/index'); }),
        Detail: load(function () { return import('src/pages/Settlement/Detail/index'); }),
    },
    MyPage: load(function () { return import('src/pages/MyPage/index'); }),
    Account: {
        Login: load(function () { return import('src/pages/Account/Login/index'); }),
        Logout: load(function () { return import('src/pages/Account/Logout/index'); }),
        Down: load(function () { return import('src/pages/Account/Down/index'); }),
    },
};
