import superagent from 'superagent';
import { Cookies } from 'react-cookie';
var Request = {
    init: function () {
        var _a;
        var agent = superagent.agent();
        var cookies = new Cookies();
        var csrftoken = (_a = cookies.get('csrftoken')) !== null && _a !== void 0 ? _a : '';
        if (csrftoken) {
            agent.set('X-CSRFToken', csrftoken);
        }
        return agent;
    },
    delete: function (url) { return Request.init().delete(url); },
    get: function (url) { return Request.init().get(url); },
    post: function (url) { return Request.init().post(url); },
    patch: function (url) { return Request.init().patch(url); },
    put: function (url) { return Request.init().put(url); },
};
export default Request;
